import {
  BW_LANGUAGE_EN,
  BW_LANGUAGE_IT
} from 'src/libs/qb-brand-web-components'

const brandConfig = {
  brandId: 'musei_del_cibo',
  name: 'Musei del Cibo di Parma',
  domain: 'museidelciboreward.com',
  fbAppName: 'museidelciboreward',
  cookiePolicyUrl: 'https://static.museidelciboreward.com/cookiepolicy.pdf',
  websiteUrl: 'https://www.museidelcibo.it',
  theme: {
    colors: {
      main: '#8B0305',
      accent: '#E6A348'
    }
  },
  supportedLanguages: [BW_LANGUAGE_EN, BW_LANGUAGE_IT],
  features: {},
  cognito: {
    region: 'eu-central-1',
    poolId: 'eu-central-1_ghR9aF6iE',
    clientId: 'g6nd84e86qfk7kqd4u615obt0',
    poolDomain: 'auth.museidelciboreward.com',
    redirectUri: 'https://museidelciboreward.com/login'
  },
  gaTrackingId: 'G-PGXJ09ME1K',
  hotjarId: '2876138'
}

export default brandConfig
