import React, { memo } from 'react'

import CustomerHelpDesk from '../../../components/userAccount/CustomerHelpDesk'
import { buildFAQLink } from './utils/appLinks.helper'
import i18n from '../../../i18n'

const CustomerHelpDeskWrapper = (props) => {
  return <CustomerHelpDesk customFaq={buildFAQLink(i18n.language)} {...props} />
}

export default memo(CustomerHelpDeskWrapper)
