import brandConfig from 'brandConfig'

const buildSiteUrl = (slug, language) => {
  return `${brandConfig.websiteUrl}/${language}/${slug}/`
}

const buildPrivacyPolicyLink = (language) => {
  return buildSiteUrl('privacy', language)
}

const buildTermsAndConditionsLink = (language) => {
  return buildSiteUrl('note-legali', language)
}

const buildFAQLink = (language) => {
  return buildSiteUrl('loyalty-program', language)
}

export {
  buildSiteUrl,
  buildPrivacyPolicyLink,
  buildTermsAndConditionsLink,
  buildFAQLink
}
