import React, { memo } from 'react'
import { BW_LABEL_TEXT } from 'src/libs/qb-brand-web-components'

import TermsPolicies from '../../../components/shared/termsPolicies/TermsPolicies'
import brandConfig from 'brandConfig'
import {
  buildPrivacyPolicyLink,
  buildTermsAndConditionsLink
} from './utils/appLinks.helper'
import i18n from '../../../i18n'

const TermsPoliciesWrapper = (props) => {
  const { t } = props
  return (
    <TermsPolicies
      links={[
        {
          label: t(BW_LABEL_TEXT.TERMS_CONDITIONS),
          to: buildTermsAndConditionsLink(i18n.language)
        },
        {
          label: t(BW_LABEL_TEXT.PRIVACY_POLICY),
          to: buildPrivacyPolicyLink(i18n.language)
        },
        {
          label: t(BW_LABEL_TEXT.COOKIES_POLICY),
          to: brandConfig.cookiePolicyUrl
        }
      ]}
      {...props}
    />
  )
}

export default memo(TermsPoliciesWrapper)
