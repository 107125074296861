import React, { memo } from 'react'
import { BW_LABEL_TEXT } from 'src/libs/qb-brand-web-components'
import { useTranslation } from 'react-i18next'

import Footer from '../../../components/shared/footer/Footer'
import i18n from '../../../i18n'
import brandConfig from 'brandConfig'
import {
  buildPrivacyPolicyLink,
  buildTermsAndConditionsLink
} from './utils/appLinks.helper'

const FooterWrapper = (props) => {
  const { t } = useTranslation()
  return (
    <Footer
      links={[
        {
          label: t(BW_LABEL_TEXT.TERMS),
          to: buildTermsAndConditionsLink(i18n.language)
        },
        {
          label: t(BW_LABEL_TEXT.PRIVACY),
          to: buildPrivacyPolicyLink(i18n.language)
        },
        {
          label: t(BW_LABEL_TEXT.COOKIES),
          to: brandConfig.cookiePolicyUrl
        }
      ]}
      {...props}
    />
  )
}

export default memo(FooterWrapper)
